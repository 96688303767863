<template>
  <v-layout justify-center width="100" class="mt-12 grad" column flex style="position:relative; height: 100%"  >
    <v-layout justify-center class="mt-n12 mb-10" v-if="verifiedUser">
      <v-card :width="$vuetify.breakpoint.mdAndUp? '40%' : '80%'"  outlined class="pl-5 pt-5 secondary" rounded="lg" >
        <v-row>
          <v-col cols="1" class="mr-2"><v-icon color="primary" :large="$vuetify.breakpoint.mdAndUp" >verified</v-icon></v-col>
          <v-col>
            <h3>Email verified successfully!</h3> 
            <p> Please login to continue!</p>
            </v-col>
            </v-row>
          </v-card>
    </v-layout>
      <v-row align="center" align-lg="start" justify="center" justify-lg="start">
          <v-col cols="12" lg="6" v-show="$vuetify.breakpoint.lgAndUp" >
              <v-layout justify-center class=" mt-12 mr-n12  " column >
                <h1 style="text-align:center;font-size: 28px; font-weight:600">Accelerate Inovation</h1>
                <h1 class="primary--text" style="text-align:center;font-size: 28px;font-weight:600">Reduce Costs</h1>
                  
            </v-layout>

            <v-layout justify-center  class="mt-12 pr-12">
              <div style="width:50%;height:100%">
            <v-img :src="require('../assets/Login1.svg')" ></v-img>
              </div>
            </v-layout>
                   <p style="font-size: 14px; font-weight:300" class="cancel--text mt-12 ml-12 pl-12 pt-5"><v-icon small color="cancel" left>copyright</v-icon>Copyright 2022. All rights reserved.</p>

          </v-col>
          <v-col cols="11" sm="9" lg="5" :class="$vuetify.breakpoint.lgAndUp?'mr-n12': 'ml-5 mr-5'">
                   
       
       <v-card  rounded="lg" elevation="0" class="pl-5 pt-3 pb-3 pr-5" style="position: relative; background: inherit">
            <v-layout class="mb-5 mt-10" justify-center>
              <h2 style="font-weight:500">LOG IN!</h2>
            </v-layout>
           
              <v-form  class="ma-5 mt-10" ref="loginForm" v-model="valid" lazy-validation >
                <v-text-field
                  label="Username *"
                  v-model="username"
                  placeholder="Enter username"
                  prepend-icon="account_box"
                  required
                  outlined 
                  :rules="usernameRules"
                  @keyup.enter="$refs.password.focus()"
                ></v-text-field>
                <v-text-field
                  label="Password *"
                  v-model="password"
                  prepend-icon="lock"
                  placeholder="Enter password"
                  type="password"
                  required
                  outlined
                 append-icon="visibility_off"
                  :rules="passwordRules"
                  ref="password"
                  @keyup.enter="login"
                ></v-text-field>
                <v-layout justify-end row class="ml-8 mr-1 mt-n5">
                   <!-- <v-checkbox class="mt-n1"
              v-model="ex4"
              label="Remenber Me"
              color="black"
              
              hide-details
            ></v-checkbox> -->

            <p class="primary--text">Forgot Password</p>

                 </v-layout>
                <v-layout justify-center class="mt-10">
                  <v-btn   class="ma-5 mb-n2 loginbutton" color="primary" @click="login">Login</v-btn>
                </v-layout>
                 
                 </v-form>

                

                  <v-layout justify-center>
                  <p   class="mb-5 button" >Not registered yet? <span class="primary--text underline button" style="text-decoration: underline" @click="gotoSignup">Sign-Up</span></p>
                </v-layout>

       </v-card>
           
              
          </v-col>

      </v-row>

      <!-- <v-layout justify-start class="mt-n12" align-content-end align-baseline style="position:fixed; width:100%; height:70px;bottom:0px; z-index:1;"> <img src="@/assets/Login.svg"  contain  style="position: absolute;bottom:0px;vertical-align: bottom; width: 25% height: auto;"></v-layout> -->

  </v-layout>
</template>

<script>
//const lazyImage = () => import('../components/lazyImage.vue')
export default {
   metaInfo: {
      vmid: 'main',
      title: 'Login',
      //titleTemplate: null,
      content: 'This is an application for subscription of various apis!'
    },

    // components:{
    //   lazyImage

    // },

  data: ()=>({
    valid: false,
    username: '',
    password: '',
    usernameRules: [
      v => !!v || "Name is required",
      v => (v && v.length >= 5) || "Name must be more than 5 characters"
    ],
    passwordRules: [
      v => !!v || "Password is required",
      v => (v && v.length > 6) || "Password must be more than 6 characters"
    ],

  }),

  methods: {
    login: function(){
      if(this.$refs.loginForm.validate()){
      this.$store.dispatch('login', {'username': this.username, 'password' : this.password})}
    },

    gotoSignup: function(){
      this.$router.push({ name : 'signup'})
    },

    
  },

  computed: {
    verifiedUser: function(){
      return this.$store.state.auth.verifiedUser;
    }
  }

}
</script>

<style>
.grad {
background-image: linear-gradient(to top, #F1F9FF , #F1F9FF, #ffffff);
}

.button{
  cursor: pointer;
}

</style>